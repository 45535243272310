import query from "storyblok/fetch"

export async function getBanner() {
  const data = await query(
    `#graphql
      query 
        {
  BannerItems (resolve_links: "url") {
    items {
      content {
        message
      }
    }
    total
  }
}
  
    `,
  )

  return data?.BannerItems?.items[0]
}
