import cn from "classnames"
import Close from "public/assets/x.svg"
import Curly from "public/assets/white-curly.svg"
import richText from "utilities/richText"

export default function Banner({ message, handleBannerClose }) {
  return (
    <div className="relative z-40 bg-secondary p-6">
      <button
        onClick={() => handleBannerClose()}
        aria-label="Close Modal"
        title="Close Modal"
        className="close-modal-btn absolute top-4 right-4 overflow-hidden z-10"
      >
        <Close />
      </button>
      <div className="max-w-screen-xl mx-auto font-primary text-primary">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex items-center">
            <Curly />

            <h3 className="text-l1 px-1">!</h3>
            <Curly className="rotate-180" />
          </div>

          <div className="md:pr-4 md:pt-4">{richText(message)}</div>
        </div>
      </div>
    </div>
  )
}
